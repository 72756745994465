/* Landing page container */
.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

  
  /* Content wrapper */
  .content2 {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for readability */
    border-radius: 10px;
  }
  
  /* Heading styles */
  .content2 h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .content2 h1 span {
    color: #f06563; /* Matching the accent color from your image */
  }
  
  /* Paragraph styles */
  .content2 p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .content2 p span {
    color: #f06563; /* Matching the accent color */
    font-weight: bold;
  }
  
  /* Buttons container */
  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  /* Primary button */
  .btn-primary {
    background-color: #f06563;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #e05552;
  }
  
  /* Secondary button */
  .btn-secondary {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-secondary:hover {
    background-color: white;
    color: #f06563;
  }
  