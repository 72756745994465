.AIContainerBox {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  height: 80%;
  padding-top: 60px;
  margin-top: 1rem;
}

.Aicontent {
  margin-left: 20rem; /* Offset by sidebar width plus margin */
  width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  display: flex;
  justify-content: center;
  height: 100vh;
  color: rgb(0, 0, 0);
  text-align: center;
}

.Aiheader {
  margin: -70px -20px 20px; /* Negative margin to pull the header upwards */
  padding: 20px;
  text-align: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  position: relative; /* Ensure it stays in place relative to .auth-box */
  z-index: 1; /* Make sure it appears above the white box */
}

.AIh1 {
  margin-bottom: 20px;
  color: #ffffff;
}

/* Main container */
.chatbot-container {
  width: 100%;
  margin: 50px auto;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow: hidden;
}

/* Messages area */
.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.3rem;
}

/* Input area */
.inputBox {
  background-color: #ffffff;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

/* Message styling */
.message {
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 18px;
  line-height: 1.4;
  word-wrap: break-word;
  position: relative;
}

/* User message styling */
.message.user {
  background-color: #0078ff;
  color: #fff;
  align-self: flex-end;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 18px;
}

/* Bot message styling */
.message.bot {
  background-color: #eaeaea;
  color: #333;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 18px;
}

/* Input styling */
.promptInput {
  width: 75%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #0163b8;
  outline: none;
  font-size: 16px;
  margin-right: 10px;
}

.aibutton {
  padding: 10px 30px;
  background-color: #0163b8;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
  outline: none;
  font-size: 16px; /* Adjust if needed */
}

.aibutton:hover {
  background: linear-gradient(0deg, #0163b8, #003b6f);
}

/* Scrollbar styling */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Animation for typing effect */
.typing-indicator {
  font-style: italic;
  color: #999;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.typing-indicator::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #999;
  animation: blink 1.4s infinite both;
  
}


.aibutton svg {
  margin-right: 8px; /* Space between the icon and the text */
  vertical-align: middle; /* Align the SVG icon with the text */
}

.aitext {
  font-weight: 600;
  font-size: medium; /* Adjust font size if needed */
}

.message.bot {
  background-color: #eaeaea;
  color: #333;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 18px;
  display: flex;
  align-items: center;
}


@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .chatbot-container {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  button {
    padding: 0 15px;
    font-size: 14px;
  }
}
