@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 10px 0;

  }
  
  .footer p {
    font-family: "SUSE", sans-serif;
    margin: 0;
    color: #000000;
  }