@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
.Admincontent {
    margin-left: 20rem; /* Offset by sidebar width plus margin */
    padding: 20px;
    width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
    box-sizing: border-box; /* Ensures padding doesn't add to width */
    font-family: "SUSE", sans-serif;
  }

  .Adminheader {
    display: flex;
    justify-content: center;
    padding: 20px;
    text-align: center;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    width: 95%
  }

  .Admin-stats-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align content to the right */
    background-color: white;
    border-radius: 10px;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    width: 24%; /* Adjust width to provide good spacing on large screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 20px; /* Space between boxes on small screens */
  }
  
  .Admin-icon-box {
    position: absolute;
    top: -20px; /* Move it slightly upwards to overlap the white box */
    left: 0px; /* Align it to the left side */
    padding: 20px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
  }

  .Admin-content-box {
    text-align: right; /* Align text to the right */
    width: 100%; /* Ensure content box takes the full width */
  }

  .Admin-content-box h4 {
    font-size: 1.3rem;
    color: #2E3A59;
    font-weight: 700;
    letter-spacing: 0.00735em;
    margin: 0px;
  }


.AssessmentDashButton{
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: #1a73e8;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Noto Sans", sans-serif;
    margin-right: 5px;
}  

.AssessmentDashButton:hover{
    background-color: #1662c4;
}

.textCentre{
    text-align: center;
    color: #ffffff; 
}

.textCentre.bigger{
    font-size: 18px;
}

.cardContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Make the container responsive */
    padding-top: 30px;
    padding-bottom: 0;
    border-radius: 10px;
    width: 100%; /* Full width to take up the available space */
    box-sizing: border-box;
}

.card {
    width: 225px; /* Adjust the width as needed */
    margin-bottom: 10px;
}





