/* General container styles */
@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
.outerContainer {
  position: relative;
  border-radius: 10px;
  padding-top: 60px; /* Space for the overlapping header */
  width: 100%; /* Extended overall width */
  margin: 0 auto;
  margin-top: 1rem;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in width */
  font-family: "SUSE", sans-serif;
}

/* Responsive adjustments for the header */
.Threatheader {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px);
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  z-index: 10;
}

/* Main content area */
.Threatcontent {
  display: flex;
  flex-direction: row; /* Side-by-side layout for large screens */
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px; /* Space between input and output boxes */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Input container */
.input-container {
  flex: 1;
  padding: 15px; /* Adjusted padding */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 6rem; /* Adjusted margin for better spacing */
  max-width: 30%; /* Ensure it fits well in larger screens */
  box-sizing: border-box; /* Ensure padding is included in width */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 20px; /* Space between sections */
  font-size: 1rem; /* Base font size */
  height: auto; /* Allow height to adjust based on content */
}

/* CVE information container */
.cve-info-container {
  width: 100vw;
  padding: 15px; /* Adjusted padding */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 75vh; /* Ensure it doesn't overflow */
  margin-top: 6rem; /* Adjusted margin */
  max-width: 65%; /* Ensure it fits well in larger screens */
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Adjustments for all screen sizes */
.Intelcontent {
  margin-left: 20rem; /* Offset by sidebar width plus margin */
  width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  
  color: rgb(0, 0, 0);
  text-align: justify;
}

.IntelContainerBox {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 55%;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Flex container for keyword, severity, and impact sections */
.section-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between keyword, severity, and impact boxes */
}

/* Individual section boxes */
.section-box {
  padding: 15px; /* Adjusted padding for better fit */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Keyword section */
.keyword-section .keyword-checkboxes {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Space between items */
}

.keyword-column {
  display: flex;
  flex-direction: column;
  flex-basis: 45%; /* Adjust for wrapping */
  box-sizing: border-box;
}

/* Severity and impact checkboxes */
.severity-checkboxes {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 10px; /* Space between items */
}

.severity-checkboxes > div {
  flex: 1 1 20%; /* Adjust the width of each checkbox container */
}

/* Button section styling */
.button-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cve-dropdown {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1a73e8;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cve-dropdown:hover {
  background-color: #1662c4;
}

.cve-info-container h2 {
  text-align: center;
  color: rgb(52, 71, 103);
  margin-bottom: 20px;
}

.highlight {
  background-color: #037de7; 
  color: white;
  font-weight: bold; 
  cursor: pointer;
}
.highlight:hover {
  background-color: #003b6f;
}

.loading-spinner {
  width: 50px; 
  height: 50px; 
  margin: auto;
  border: 6px solid;
  border-color: #037de7 transparent #037de7 transparent;
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
}

input[type="checkbox"] {
  display: none;
}

/* Custom checkbox container */
.custom-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 2px solid #ccc;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  vertical-align: middle;
  transition: border-color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

/* Tick mark (shown when checked) */
.custom-checkbox::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

/* Show the tick mark when checked */
input[type="checkbox"]:checked + .custom-checkbox::after {
  display: block;
}

/* Change the checkbox appearance when checked */
input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #06b6d4;
  border-color: #06b6d4;
  
}

/* Add hover effect: Change border color */
.custom-checkbox:hover {
  border-color: #06b6d4;
  
}

/* Style for the labels */
.fontBigger {
  font-size: 1vw;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cve-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cve-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.cve-table .cve-label {
  width: 30%;
  font-weight: bold;
  background-color: #f9f9f9; /* Optional for better distinction */
  border: 1px solid #ddd;
}

.cve-table .cve-data {
  width: 70%;
}

.cve-separator {
  margin-top: 10px;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries for Various Screen Sizes */

/* For viewports below 1500px */
@media (max-width: 1600px) {
  .input-container {
    max-width: 45%; /* Adjust width for better fit */
    padding: 12px; /* Adjusted padding */
    font-size: 0.7rem; /* Reduce font size */
  }

  
}

/* For viewports below 1300px */
@media (max-width: 1400px) {
  .input-container {
    max-width: 50%; /* Adjust width for better fit */
    padding: 10px; /* Adjusted padding */
    font-size: 0.7rem; /* Reduce font size */
  }

}

/* For viewports below 1200px */
@media (max-width: 1200px) {
  .input-container {
    max-width: 60%; /* Adjust width for better fit */
    padding: 8px; /* Adjusted padding */
    font-size: 0.7rem; /* Reduce font size */
  }

  
}

/* For viewports below 1100px */
@media (max-width: 1100px) {
  .input-container {
    max-width: 70%; /* Adjust width for better fit */
    padding: 6px; /* Adjusted padding */
    font-size: 0.6rem; /* Reduce font size */
  }

}


