.auth-containerUp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-repeat: no-repeat;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 0 15px; /* Add some padding to prevent content from touching edges */
}

.auth-boxUp {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 700px;
    max-width: 100%; /* Ensure it doesn't exceed the viewport */
    position: relative; /* Ensure that .sign-in-header can be positioned absolutely */
}

.sign-in-headerUp {
    margin: -60px -20px 20px; /* Negative margin to pull the header upwards */
    padding: 30px;
    text-align: center;
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    color: rgb(52, 71, 103);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    position: relative; /* Ensure it stays in place relative to .auth-box */
    z-index: 1; /* Make sure it appears above the white box */
}

.Signh2Up {
    margin-bottom: 20px;
    color: #ffffff;
}

.social-icons {
    margin-bottom: 20px;
}

.social-icons i {
    font-size: 24px;
    margin: 0 15px;
    cursor: pointer;
    color: #007bff;
}

.form_inputUp {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.SignbuttonUp {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.SignbuttonUp:hover {
    background-color: #0056b3;
}

.sign-up-textUp {
    margin-top: 20px;
    color: #7b809a;
}

.sign-up-textUp a {
    color: #007bff;
    text-decoration: none;
}

.sign-up-textUp a:hover {
    text-decoration: underline;
}

.form-row {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
    justify-content: space-between; /* Space out the input fields */
    margin-bottom: 15px; /* Space between rows */
}

.form_inputUp {
    flex: 1 1 calc(48% - 10px); /* Adjust the width of input fields */
    margin-right: 10px; /* Add space between input fields */
}

.form_inputUp:last-child, /* Remove margin for the last item to prevent overflow */
.form_inputUp:nth-child(2n) {
    margin-right: 0; /* No margin on the right for the last item in the row */
}

@media (max-width: 768px) {
    .form_inputUp {
        flex: 1 1 100%; /* Stack inputs vertically on smaller screens */
        margin-right: 0; /* Reset right margin */
    }

    .form-row {
        flex-direction: column; /* Stack items vertically */
    }
}

/* Responsive styles */
@media (max-width: 768px) {
    .auth-boxUp {
        padding: 20px; /* Reduce padding for smaller screens */
        width: 90%; /* Allow for more width on small screens */
        margin: 0 auto; /* Center the box */
    }

    .form_inputUp {
        padding: 8px; /* Adjust input padding for smaller screens */
    }

    .SignbuttonUp {
        padding: 8px; /* Adjust button padding for smaller screens */
        font-size: 14px; /* Smaller font size */
    }
}

@media (max-width: 480px) {
    .sign-in-headerUp {
        padding: 20px; /* Reduce padding for header */
    }

    .Signh2Up {
        font-size: 18px; /* Adjust font size for the heading */
    }
}