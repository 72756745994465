/* Sidebar container styles */
.sidebar {
    width: 15rem;
    background-color: #290448;
    padding: 20px;
    color: white;
    border-radius: 15px;
    margin: 8px;
    position: fixed;
    left: 8px;
    top: 8px;
    bottom: 8px;
    transition: transform 0.3s ease;
    z-index: 1000;
    font-family: "SUSE", sans-serif;
    overflow-y: auto;
  }

  .sidebar::-webkit-scrollbar {
    width: 6px; /* Customize the width of the scrollbar */
  }

  .sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3); /* Style scrollbar thumb */
    border-radius: 10px;
  }

  .sidebar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .sidebar img {
    height: 50%;
    width: 50%;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }

  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Sidebar navigation */
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 10px;
  }
  
  /* Sidebar links */
  .sidebar nav ul li a {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Sidebar icons */
  .sidebar nav ul li a .icon {
    margin-right: 10px;
  }
  
  /* Hover effect */
  .sidebar nav ul li a:hover {
    background-color: #495057;
  }
  
  /* Active link styling */
  .sidebar nav ul li a.active {
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    color: white;
    font-weight: bold;
  }
  .sidebar-link{
    cursor: pointer;
  }
  
  /* Toggle button */
  .toggle-btn {
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #343a40;
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2000;
  }
  
  .titlecentre{
    text-align: center;
    display: flex;
    align-items: center;
  }
 
  
  /* Responsive design */
  @media (max-width: 768px) {
    .sidebar {
      transform: translateX(-260px);
      margin: 8px;
      border-radius: 15px;
      left: 8px;
      top: 8px;
    }
  
    .toggle-btn {
      display: block;
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .content {
      margin-left: 0;
      width: 100%;
    }
  }
  