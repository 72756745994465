.glossarycontent {
  margin-left: 15rem; /* Offset by sidebar width plus margin */
  width: calc(100% - 15rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  display: flex;
  align-items: top;
  justify-content: center;
  height: 100vh;
  background-color: rgb(240, 242, 245);
  color: rgb(0, 0, 0);
  text-align: center;
  overflow: hidden; /* Hide overflow to manage scrolling */
}

.GlossContainerBox {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%; 
  margin-bottom: 35px;
  margin-top: 3rem;
  position: relative;
}

.Glossheader {
  margin: -70px -20px 20px; /* Negative margin to pull the header upwards */
  padding: 30px;
  text-align: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  position: sticky; /* Ensure it stays in place relative to .auth-box */
  z-index: 100; /* Ensure it stays on top */
}


.glossaryTable {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 20rem); /* Adjust based on header height and padding */
  width: 100%;
}


.glossaryTable table {
  border-collapse: collapse;
}

.glossaryTable th,
.glossaryTable td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.glossaryTable th {
  background:  rgb(26, 115, 232);
  border: 1px solid white;
  color: white;
  text-align: left;
  padding: 8px;
}

.glossaryTable thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.SourceLink {
  color: blue;
  text-decoration: underline;
}

.SourceLink:hover {
  text-decoration: none;
}

.letterbutton {
  background-color: transparent;
  border: none; /* Remove borders */
  color: white; 
  padding: 10px 20px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 16px; 
  margin: 5px 2px; 
  cursor: pointer; 
  border-radius: 5px; 
}

.letterbutton:hover {
  background-color: white; /* White background */
  color: black; /* Black text */
}

