@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

.dashcontent {
  margin-left: 20rem; /* Offset by sidebar width plus margin */
  padding: 20px;
  width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  background-color: rgb(240, 242, 245);
  font-family: "SUSE", sans-serif;
}

.dashboard-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Make the container responsive */
  padding-top: 24px;
  padding-bottom: 0;
  border-radius: 10px;
  width: 100%; /* Full width to take up the available space */
  box-sizing: border-box;
}

.dashboard-container.level2 {
  padding-top: 15px;
}

.dashboard-container.level3 {
  padding-top: 10px;
}
.dashboard-container.level4 {
  padding-top: 40px;
}
.stats-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align content to the right */
  background-color: white;
  border-radius: 10px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
  width: 24%; /* Adjust width to provide good spacing on large screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 20px; /* Space between boxes on small screens */
}

.icon-box {
  position: absolute;
  top: -20px; /* Move it slightly upwards to overlap the white box */
  left: 0px; /* Align it to the left side */
  padding: 20px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

.icontable {
  position: absolute;
  top: 20px; /* Distance from the top */
  right: 20px; /* Distance from the right */
  padding: 20px; /* Optional padding */
  border-radius: 10px;
  display: inline-flex;
  color: white;
  font-size: 24px;
}

.black {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.blue {
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.green {
  background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(76, 175, 79, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.red {
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(233, 30, 98, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orange {
  background: linear-gradient(195deg, rgb(236, 147, 64), rgb(216, 112, 27));
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(233, 111, 30, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}


.content-box {
  text-align: right; /* Align text to the right */
  width: 100%; /* Ensure content box takes the full width */
}

.content-box h3 {
  font-size: 0.85vw;
  letter-spacing: 0.02857em;
  font-weight: 700;
}

.content-box h4 {
  font-size: 1.3rem;
  color: rgb(87, 12, 24);
  font-weight: 700;
  letter-spacing: 0.00735em;
  margin: 0px;
}


.change-text {
  font-size: 14px;
  color: green;
  margin-top: 10px;
}

.update-text {
  font-size: 14px;
  color: grey;
  margin-top: 10px;
}

.hrLine{
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
}

.dashboard-card {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 90%;
  height: 95%;
}


/* Card content styling */
.dashboard-card-content {
  padding: 20px;
  padding-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: rgb(0, 0, 0);

}

.card-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.card-subtitle {
  color: rgba(0, 0, 0, 0.8);
}

.card-divider {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin: 10px 0;
}

.card-update-info {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
}

/* Table styling */
.dashboard-table {
  width: 100%;
  margin-top: 0 auto;
  border-collapse: collapse;
  color: black;
}

.table-header {
  background-color: #f8fafb;
  border-bottom: 1px solid #ccc;
}

.table-row {
  background-color: rgba(255, 255, 255, 0.1);
   /* Adds horizontal border */
}
.bluetable{
  border-bottom: 1px solid #64a7ff;
}
.redtable{
  border-bottom: 1px solid #fd92c8;
}
.greentable{
  border-bottom: 1px solid #399e25;
}

.orangetable{
  border-bottom: 1px solid #ff9c4a;
}

.table-cell {
  padding: 18px 14px;
  text-align: left;
  color: #404b58;
  font-size: 1vw;
}

.Sixteen-table-cell {
  padding: 12px 8px;
  text-align: left;
  color: #404b58;
  font-size: 0.8vw;
}

.table-header .table-cell {
  font-weight: bold;
  color: #2f3947;
}

.Tableheader {
  padding: 10px;
  text-align: center;
  color: rgb(52, 71, 103);
  border-radius: 10px;
  position: relative; /* Ensure it stays in place relative to .auth-box */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tableheader.graphs {
  margin: -70px -20px 20px; /* Negative margin to pull the header upwards */
}




.chart-container {
  flex: 1;
  min-width: 0;
  position: relative;
}

canvas {
  width: 100% !important;
  height: auto;
}

.keyicons{
  color: #36a2eb;
}
.keyicons2{
  color: #ff5e81;
}
.swiper-pagination-bullet {
  width: 35px !important;
  height: 35px !important;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  color: #000 !important;
  opacity: 1 !important;
  background: transparent !important;
}

.swiper-pagination-bullet-active {
  color: #000000 !important;
  background: #e6feff !important;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items:  flex-start;
}

.graphSize{
  height: 100%;
  width: 100%;
}




/* Media Query for responsiveness */
@media (max-width: 1024px) {
  .dashboard-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around; /* Keep boxes evenly distributed */
  }

  .stats-box {
    width: 45%; /* Make each box take up half of the width on smaller screens */
    margin-bottom: 20px; /* Add spacing between boxes */
  }
}

@media (max-width: 768px) {
  .stats-box {
    width: 100%; /* Make each box take up full width on small screens */
  }

  .icon-box {
    top: -20px;
    left: 15px; /* Adjust icon positioning for smaller screens */
  }
}
