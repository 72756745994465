.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);
  text-align: center;
}

.auth-box {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 350px;
  position: relative; /* Ensure that .sign-in-header can be positioned absolutely */
  
}

.sign-in-header {
  margin: -60px -20px 20px; /* Negative margin to pull the header upwards */
  padding: 30px;
  text-align: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(52, 71, 103);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  position: relative; /* Ensure it stays in place relative to .auth-box */
  z-index: 1; /* Make sure it appears above the white box */
}


.Signh2 {
  margin-bottom: 20px;
  color: #ffffff;
}

.social-icons {
  margin-bottom: 20px;
}

.social-icons i {
  font-size: 24px;
  margin: 0 15px;
  cursor: pointer;
  color: #007bff;
}

.form_input {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}



.Signbutton {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.Signbutton:hover {
  background-color: #0056b3;
}

.sign-up-text {
  margin-top: 20px;
  color: #7b809a;
}

.sign-up-text a {
  color: #007bff;
  text-decoration: none;
}

.sign-up-text a:hover {
  text-decoration: underline;
}

.buttonNext {
  font-size: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.buttonNext:hover {
  background-color: #0056b3;
}