.ContainerBox {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 55%; 
}

.accountcontent {
  margin-left: 20rem; /* Offset by sidebar width plus margin */
  width: calc(100% - 20rem); /* Adjust width to account for sidebar and margins */
  box-sizing: border-box; /* Ensures padding doesn't add to width */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(240, 242, 245);
  color: rgb(0, 0, 0);
  text-align: center;
}

.accountheader {
  margin: -70px -20px 20px; /* Negative margin to pull the header upwards */
  padding: 30px;
  text-align: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(52, 71, 103);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  position: relative; /* Ensure it stays in place relative to .auth-box */
  z-index: 1; /* Make sure it appears above the white box */
}

.accounth1 {
  margin-bottom: 20px;
  color: #ffffff;
}


.fontChange{
  font-family: "Noto Sans", sans-serif;
  color: #050052; 
}



.buttonForgot {
    background-color: #0163b8;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .buttonForgot:hover {
    background-color: #003b6f;
  }

.forgotTitle{
  text-decoration: underline;
  color: #050052; 
}